<template>
  <div class="c-btn-box" :class="fix ? 'btn-fix' : ''" :style="{ 'display': flex, 'padding': isPadding ? '': '0px'  }">
    <van-button type="default" :class="[buttonObj.claSS]" @click="clickItem(buttonObj)">{{ buttonObj.title }}</van-button>
  </div>
</template>

<script>
  export default {
    props: {
      isPadding: {
        type: Boolean,
        default() {
          return true
        }
      },
      buttonObj: {
        type: Object,
        default() {
          return {
            claSS: 'c-btn-default',
            title: '',
            event: ''
          }
        }
      },
      fix: {
        type: Boolean,
        default() {
          return false
        }
      },
      flex: {
        type: String,
        default() {
          return 'flex'
        }
      }
    },
    methods: {
      clickItem({ event }) {
        this.$emit('clickItem', event)
      }
    }
  }
</script>

<style lang="scss">
.c-btn-box {
  padding: 0 15px;
  display: flex;
  justify-content: center;
  flex: 1;
  button {
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    letter-spacing: 2px;
  }
  // 小按钮
  .c-btn-border-small {
    height: 36px;
    font-size: 14px;
  }
  // 有radius
  .c-btn-border-radius {
    border-radius: 30px;
  }
  // 默认按钮
  .c-btn-default {
    background: $main-color;
    color: #ffffff;
  }
  // 白色背景
  .c-btn-bgwhite {
    background:  #ffffff;
    color: $main_color;
  }
   /*退出按钮*/
  .c-btn-cancel {
    background: #a7a7af;
  }
  /*警告按钮红色，删除（按钮组删除）*/
  .c-btn-delete {
    background: #FA4441;
  }
  
  .c-btn-text-color-red {
    color: $main_color;
  }

  .c-btn-text-color-white {
    color: $white;
  }
}

.btn-fix {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>