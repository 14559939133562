<template>
 <div class="v_change_pwd g-flex-column">
   <head-back>
     <template>
        <span>{{titleName}}</span>
      </template>
   </head-back>
   <div class="v-change-pwd-content">
     <div class="v-change-pwd-form">
       <div class="v-change-pwd-form-item">
          <van-field v-model="userInfo.mobile" label="绑定手机号:" readonly placeholder="请输入绑定手机号"/>
       </div>
       <div class="v-change-pwd-form-item v-login-form-item-pwd">
          <van-field v-model="form.old_password"  :type="pwdType" label="原密码:" placeholder="请输入原密码"/>
          <i class="iconfont" :class="pwdType ==='password' ? 'iconbukejian1': 'iconmimakejianguanbi'" @click="pwdTypeClick"></i>
       </div>
     </div>

     <div class="v-change-pwd-form">
       <div class="v-change-pwd-form-item v-login-form-item-pwd">
          <van-field v-model="form.new_password" :type="pwdSecondType" label="新密码：" placeholder="请输入新密码"/>
          <i class="iconfont" :class="pwdSecondType ==='password' ? 'iconbukejian1': 'iconmimakejianguanbi'" @click="pwdTypeSecondClick"></i>
       </div>
       <div class="v-change-pwd-form-item v-login-form-item-pwd">
          <van-field v-model="form.newSecondpassword" :type="pwdThirdType" label="确认新密码：" placeholder="请再次输入新密码"/>
          <i class="iconfont" :class="pwdThirdType ==='password' ? 'iconbukejian1': 'iconmimakejianguanbi'" @click="pwdTypeThirdClick"></i>
       </div>
     </div>

     <div class="v-change-pwd-btn g-flex-justify-between">
      <operation-button :buttonObj="buttonObj" @clickItem="emitLoginClick"/>
    </div> 
   </div>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import OperationButton from '../../components/OperationButton.vue'
import { apiChangePassword } from '@/utils/api.js'
export default {
  components: { HeadBack, OperationButton },
  data() {
    return {
      pwdType: 'password',
      pwdSecondType: 'password',
      pwdThirdType: 'password',

      titleName: '修改登录密码',
      text: '',
      buttonObj: {
        title: '确定修改',
        claSS: 'c-btn-default',
        event: 'apiChangePasswordHandel'
      },
      form: {
        old_password: '',
        new_password: '',
        newSecondpassword: ''
      },
    }
  },
  methods: {
    // 修改按钮触发
    emitLoginClick(event) {
      if(!event) return
      this[event]()
    },

    async apiChangePasswordHandel() {
      if(!this.form.old_password) return this.$toast('请输入当前密码')
      if(!this.form.new_password) return this.$toast('请输入新密码')
      if(this.form.new_password !== this.form.newSecondpassword) return this.$toast('两次密码输入不一致')
      this.$global.loadingShow = true
      const { success, data } = await apiChangePassword(this.form)
      if(!success) return
      this.$toast(data.msg)
      setTimeout(()=> {
        this.$router.go(-1)
      }, 500)
    },

    pwdTypeClick() {
      if(this.pwdType === 'password') return this.pwdType = 'text'
      return this.pwdType = 'password'
    },

    pwdTypeSecondClick() {
      if(this.pwdSecondType === 'password') return this.pwdSecondType = 'text'
      return this.pwdSecondType = 'password'
    },
    
    pwdTypeThirdClick() {
      if(this.pwdThirdType === 'password') return this.pwdThirdType = 'text'
      return this.pwdThirdType = 'password'
    }
  },
  computed: {
    userInfo() {
      return this.$global.userInfo
    }
  }
}
</script>

<style lang='scss'>
.v_change_pwd {
  height: 100%;
  overflow: auto;
  .v-change-pwd-content {
    flex: 1;
    overflow: auto;
    background: #F1F1F1;
    .v-change-pwd-form {
      margin-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background: $white;
      &:nth-of-type(2) {
        margin-top: 20px;
      }
      .v-change-pwd-form-item {
        border-bottom: 1px solid #F1F1F1;
        // 密码
        &.v-login-form-item-pwd {
          position: relative;
          .iconfont {
            position: absolute;
            right: 0;
            font-size: 24px;
            padding: 5px;
            top: 50%;
            transform: translateY(-50%);
            color: $main_color;
          }
        }
        .van-cell {
          padding: 20px 0;
          .van-field__label {
            color: $main_color;
            font-size: 15px;
          }
        }
        &:nth-last-of-type(1) {
          border-bottom: none;
        }
      }
    }


    .v-change-pwd-btn {
      margin-top: 30px;
    }
  }
}
</style>